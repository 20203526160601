


























































































































































































































import { Vue, Component, Ref } from 'vue-property-decorator'
import { ConfiguracaoDeCupom, Page, Pageable } from '@/models'
import Confirmacao from '@/components/ui/Confirmacao.vue'
import DialogoDeEdicaoDeConfiguracaoDeCupons from '@/components/cupons/DialogoDeEdicaoDeConfiguracaoDeCupons.vue'
import {
	FindConfiguracaoDeCupomUseCase,
	DeleteConfiguracaoDeCupomUseCase,
	SaveConfiguracaoDeCupomUseCase,
} from '@/usecases'
import AlertModule from '@/store/vuex/aplicacao/AlertModule'
import { dateToPtBrFormat } from '@/shareds/date/date-utils'
import AppLoading from '@/components/ui/AppLoading.vue'
import axios, { CancelTokenSource } from 'axios'
import RangeDatePicker from '@/components/ui/RangeDatePicker.vue'
import moment from 'moment'

@Component({
	components: {
		DialogoDeEdicaoDeConfiguracaoDeCupons,
		Confirmacao,
		AppLoading,
		RangeDatePicker,
	},
})
export default class TelaDeCupons extends Vue {
	@Ref()
	dialogoDeEdicaoDeConfiguracaoDeCupons!: DialogoDeEdicaoDeConfiguracaoDeCupons

	configuracoesDeCupons: ConfiguracaoDeCupom[] = []
	findConfiguracaoDeCupomUseCase = new FindConfiguracaoDeCupomUseCase()
	deleteConfiguracaoDeCupomUseCase = new DeleteConfiguracaoDeCupomUseCase()
	saveConfiguracaoDeCupomUseCase = new SaveConfiguracaoDeCupomUseCase()
	dateToPtBrFormat = dateToPtBrFormat

	busca = ''
	carregando = false
	somenteComDataExpirada = false
	datas = []

	pagina: Page<ConfiguracaoDeCupom> | null = null
	paginado: Pageable = {
		page: 0,
		size: 8,
		sort: [],
	}

	cancelToken: CancelTokenSource | null = null

	headers = [
		{ text: 'Código', value: 'codigo' },
		{ text: 'Descrição', value: 'descricao' },
		{ text: 'Início Vigência', value: 'dataInicialVigencia' },
		{ text: 'Fim Vigência', value: 'dataFinalVigencia' },
	]

	async created() {
		await this.buscarPagina()
	}

	async buscarPagina() {
		try {
			this.carregando = true
			this.cancelToken = axios.CancelToken.source()
			this.pagina = await this.findConfiguracaoDeCupomUseCase.findCupons(
				{
					busca: this.busca,
					dataInicial: this.datas[0]
						? this.gerarDataHora(this.datas[0], '00:00:00')
						: undefined,
					dataFinal: this.datas[1]
						? this.gerarDataHora(this.datas[1], '23:59:59')
						: undefined,
					somenteComDataExpirada: this.somenteComDataExpirada,
					page: this.paginado.page || 0,
					size: this.paginado.size || 5,
				},
				{
					cancelToken: this.cancelToken.token,
				},
			)
			this.paginado.page = this.pagina.number
			this.configuracoesDeCupons = this.pagina.content
		} catch (error: any) {
			AlertModule.setError('Problema ao carregar os cupons de desconto')
			this.carregando = false
		} finally {
			this.carregando = false
		}
	}

	gerarDataHora(
		data?: string | null,
		hora?: string | null,
	): string | undefined {
		return moment(`${data} ${hora}`, 'YYYY-MM-DD HH:mm').toISOString(true)
	}

	atualizarCardDeCupons(configuracaoDeCupom: ConfiguracaoDeCupom) {
		const indice = this.configuracoesDeCupons.findIndex(
			cupomExistente => cupomExistente.id === configuracaoDeCupom.id,
		)
		indice === -1
			? this.configuracoesDeCupons.push(configuracaoDeCupom)
			: this.configuracoesDeCupons.splice(indice, 1, configuracaoDeCupom)
	}

	async gerarCupons(configuracaoDeCupom: ConfiguracaoDeCupom) {
		if (!configuracaoDeCupom) return
		this.carregando = true
		configuracaoDeCupom.statusConfiguracaoDeCupom = 'Gerando'
		try {
			await this.saveConfiguracaoDeCupomUseCase.update(configuracaoDeCupom)
			await this.saveConfiguracaoDeCupomUseCase.criarCupons(configuracaoDeCupom)
			AlertModule.setInfo(
				'Os cupons de desconto estão sendo gerados. Assim que o processo for concluído, você será notificado.',
			)
		} catch (error: any) {
			this.carregando = false
			AlertModule.setError(error)
		} finally {
			this.carregando = false
		}
	}

	async removerCupomDeDesconto(indice: number) {
		const configuracaoDeCupom = this.configuracoesDeCupons[indice]
		if (!configuracaoDeCupom) {
			AlertModule.setError('Problema ao remover cupom de desconto')
			return
		}

		try {
			this.carregando = true
			await this.deleteConfiguracaoDeCupomUseCase.deleteById(
				configuracaoDeCupom.id,
			)
			this.configuracoesDeCupons = this.configuracoesDeCupons.filter(
				({ id }) => id !== configuracaoDeCupom.id,
			)
			AlertModule.setSuccess('Cupom removido com sucesso')
		} catch (error: any) {
			AlertModule.setError(error)
		} finally {
			this.carregando = false
		}
	}

	async pagePrevious() {
		this.paginado?.page ? (this.paginado.page -= 1) : (this.paginado.page = 0)
		await this.buscarPagina()
	}

	async pageNext() {
		this.paginado = {
			...this.paginado,
			page: (this.paginado?.page || 0) + 1,
		}

		await this.buscarPagina()
	}

	returnColor(configuracaoDeCupom: ConfiguracaoDeCupom) {
		if (
			configuracaoDeCupom.qtdDeCuponsDisponiveis === 0 &&
			configuracaoDeCupom.qtdDeCuponsGerados &&
			configuracaoDeCupom.qtdDeCuponsGerados > 0
		) {
			return 'blue'
		}
		
		const dateFinalVigencia = new Date(configuracaoDeCupom.dataFinalVigencia as string)
		if (dateFinalVigencia < new Date()) {
			return 'red'
		}

		return 'white'
	}

	formatarMenssagem() {
		return `
				<div><h2 class="formatarAviso">Aviso!</h2></div>
				<div class="mt-4">
					Tem certeza de que deseja remover esta configuração de cupom? 
					Ao fazer isso, todos os cupons associados a ela serão excluídos.
				</div>
			`
	}
}
